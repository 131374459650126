import { createContext } from 'react'
import { checkCookie } from './utils/cookies'

let initialState = {
  cart: {},
  location: {},
  user: {},
  loyalty: {},
  gigyaScript: {},
  showQuickViewModal: false,
  branchLoaded: false,
  registeredLogin: false,
  googleLoaded: false,
  isAdobeLoaded: false,
  isClaripLoaded: false,
  tooltipOpen: false,
  deferredPrompt: {},
  checkoutInstallModal: false,
  adobe: {},
  deviceId: '',
  recentlyViewed: [],
  recentlySearched: [],
  ionContent: null,
  showBackToTop: false,
  badgeFirstVisit: {}
}

if (typeof Storage !== 'undefined' && checkCookie()) {
  initialState = {
    cart: localStorage.getItem('cart') && JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')!) : {},
    location: localStorage.getItem('location')
      ? JSON.parse(localStorage.getItem('location')!)
      : {},
    deviceId: localStorage.getItem('deviceId') || '',
    user: localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')!)
      : {},
    loyalty: localStorage.getItem('loyaltyData') ? JSON.parse(localStorage.getItem('loyaltyData')) : {},
    gigyaScript: {},
    tooltipOpen: false,
    showQuickViewModal: false,
    branchLoaded: false,
    registeredLogin: false,
    googleLoaded: false,
    isAdobeLoaded: false,
    isClaripLoaded: false,
    deferredPrompt: {},
    checkoutInstallModal: false,
    adobe: {},
    recentlyViewed: localStorage.getItem('recentlyViewed') ? JSON.parse(localStorage.getItem('recentlyViewed')) : [],
    recentlySearched: localStorage.getItem('recentlySearched') ? JSON.parse(localStorage.getItem('recentlySearched')) : [],
    ionContent: null,
    showBackToTop: false,
    badgeFirstVisit: localStorage.getItem('firstVisit') && JSON.parse(localStorage.getItem('firstVisit')) ? JSON.parse(localStorage.getItem('firstVisit')!) : {}
  }
}

const Context = createContext<any>(initialState)

export default Context
