/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @next/next/no-css-tags */
import React, { useEffect, useContext, useRef, memo, useImperativeHandle, forwardRef } from 'react'
import { IonContent, IonPage, IonApp, setupIonicReact, IonHeader, IonFooter } from '@ionic/react'
import Provider from '../Provider'
import Footer from '../components/Footer'
import Head from 'next/head'
import Header from '../components/Header'
import MainMenu from '../components/MainMenu'
import withAppInsights from '../AppInsights'
import { build } from '../metadata.json'

import { useAuthAPI } from '../hooks/api.hooks'
import { useGetCart } from '../hooks/cart.hooks'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Our Custom CSS */
import '../sass/main.scss'

import Context from '../context'
import { LoadingProvider } from '../LoadingContext'
import getConfig from 'next/config'
import { Router, useRouter } from 'next/router'
import SkipToMain from '../components/SkipToMain'
import { checkCookie } from '../utils/cookies'

const { publicRuntimeConfig } = getConfig()

declare module 'react' {
  // eslint-disable-next-line no-unused-vars, no-undef
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    // eslint-disable-next-line camelcase
    s_objectid?: string;
  }
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gigyaScript: any,
    popEvent: any,
    google: any,
    ka: any,
    safari: any;
    // eslint-disable-next-line camelcase
    br_data: any,
    BrTrk: any,
    adobe: any,
    Visitor: any,
    fbq: any,
    pwr: any,
    // eslint-disable-next-line camelcase
    br_related_rid: string,
    branch: any,
    claripDNSSCustomerData: any,
    claripCookieManager: any,
    SprinklrGlobal: any
  }
}

const Layout = forwardRef((props: { children: string, cb: Function }, ref: React.MutableRefObject<typeof Layout>) => {
  const { dispatch, state } = useContext(Context)
  // eslint-disable-next-line no-undef
  const ionContent = useRef<HTMLIonContentElement>()
  const router = useRouter()
  const { cpref } = router.query
  const authAPICall = useAuthAPI()
  const getCartCall = useGetCart()

  const scrollToTop = (duration = 0) => {
    ionContent.current.scrollToTop(duration)
  }

  const getScrollPosition = async () => {
    const response = await ionContent.current.getScrollElement()
    return response.scrollTop
  }

  const scrollToPosition = (position) => {
    ionContent.current.scrollToPoint(0, position, 1000)
  }

  const getStoreNumber = () => {
    return state.cart.store?.storenumber
  }

  useEffect(() => {
    const gigyaPathnames = ['/aboutme', '/changepassword', '/phone-registration', '/completeregistrationname', '/aboutme']
    const restrictedPathnames = [...gigyaPathnames, '/myinformation', '/orders', '/contact-preferences', '/profile', '/editmyinformation', '/paymentmethods', '/newdevice', '/registration-phone']
    const path = router.pathname
    if (!state.user.authType && restrictedPathnames.includes(path)) {
      router.push({
        pathname: '/login',
        query: { redirectedURL: path.substr(1) }
      })
    } else if (Object.keys(state.gigyaScript).length && gigyaPathnames.includes(path)) {
      const getAccountInfoResponse = (response) => {
        if (response.errorCode !== 0) {
          router.push({
            pathname: '/login',
            query: { redirectedURL: path.substr(1) }
          })
        }
      }
      state.gigyaScript.accounts.getAccountInfo({
        callback: getAccountInfoResponse
      })
    } else if (Object.keys(state.gigyaScript).length) {
      state.gigyaScript.accounts.getAccountInfo({
        callback: (response) => {
          if (response.errorCode !== 0) {
            const emptyUser = {
              UID: '',
              UidSignature: '',
              UidSignatureTimestamp: ''
            }
            dispatch({ type: 'USER_CUS_DATA', payload: emptyUser })
          }
        }
      })
    }
  }, [state.gigyaScript])

  useEffect(() => {
    if (!state.user.appSessionToken && !state.user.UID && state.deviceId) {
      const getUserIfNoneExists = async () => {
        const data = await authAPICall('user', 'anonymous', 'POST', {}, {})
        if (!data.error) {
          const user = {
            appSessionToken: data.appSessionToken,
            UID: data.customerGuid,
            authType: 0
          }
          dispatch({ type: 'USER_CUS_DATA', payload: user })
        }
      }
      getUserIfNoneExists()
    }

    if (state.user.appSessionToken && !['/cart', '/checkout', '/registration'].includes(router.pathname)) {
      getCartCall()
    }
  }, [state.user, state.deviceId])

  useEffect(() => {
    props.cb(state.cart.store)
  }, [state.cart.store?.storenumber])

  useEffect(() => {
    dispatch({ type: 'SET_ION_CONTENT', payload: ionContent })
  }, [ionContent])

  useImperativeHandle(ref, () => ({
    scrollToTop: scrollToTop,
    getScrollPosition: getScrollPosition,
    scrollToPosition: scrollToPosition,
    getStoreNumber: getStoreNumber
  }))

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (checkCookie()) {
        if (!sessionStorage.getItem('adobe_site_visit')) {
          sessionStorage.setItem('adobe_site_visit', 'true')
        }
      }
    }
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeComplete', (url) => {
      window.branch.track('pageview')
      const path = url.split('/')
      if (path[1] !== '' && path[1] !== 'p' && path[1] !== 'c' && path[1] !== 'collection' && path[1] !== 'article' && path[1] !== 'articles' && path[1] !== 'collage' && path[1] !== 'search' && path[1] !== 'orderplaced') {
        window.br_data = window.br_data || {}
        window.br_data.acct_id = publicRuntimeConfig.BR_ID
        window.br_data.ptype = 'other'
        window.br_data.test_data = publicRuntimeConfig.BR_TEST_DATA
        window.br_data.user_id = state.user.UID || state.deviceId
        window.br_data.view_id = state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE;

        (() => {
          const brtrk = document.createElement('script')
          brtrk.type = 'text/javascript'
          brtrk.async = true
          brtrk.src = `//cdn.brcdn.com/v1/br-trk-${publicRuntimeConfig.BR_ID}.js?v=${Date.now()}`
          const s = document.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(brtrk, s)
        })()
      }
    })

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', appHeight)
      appHeight()
    }
  }, [])

  useEffect(() => {
    if (checkCookie()) {
      if (!localStorage.getItem('build')) {
        localStorage.setItem('build', `${build}`)
      } else if (localStorage.getItem('build') !== `${build}`) {
        if (localStorage.getItem('recentlyViewed')) {
          localStorage.removeItem('recentlyViewed')
        }
        localStorage.setItem('build', `${build}`)
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(names => {
            for (const name of names) {
              caches.delete(name)
            }
          })
        }
        location.reload()
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        dispatch({ type: 'SET_DEFERRED_PROMPT', payload: e })
      })
    }
  }, [])

  useEffect(() => {
    if (!state.isAdobeLoaded) {
      const targetScript = document.createElement('script')
      targetScript.src = publicRuntimeConfig.TARGET_URL
      targetScript.async = true
      targetScript.defer = true

      const targetPageParamsScript = document.createElement('script')

      const code = `
      function targetPageParams() {
        return {
          "at_property": "5199098e-e389-3261-23fe-a4b2b05261d6"
        };
      }
      `
      targetPageParamsScript.appendChild(document.createTextNode(code))
      targetScript.onload = () => {
        dispatch({ type: 'SET_ADOBE_LOADED', payload: true })
        const fingerprint = async () => {
          window.Visitor?.getInstance('B2811C8B533097DC0A490D4D@AdobeOrg').getVisitorValues((values) => {
            dispatch({ type: 'SET_DEVICE_ID', payload: values.MCMID })
          })
        }
        if (!state.deviceId && checkCookie()) {
          fingerprint()
        }
      }
      document.body.appendChild(targetScript)
      document.body.appendChild(targetPageParamsScript)
    }

    if (!state.googleLoaded) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${publicRuntimeConfig.MAPS_API_KEY}&libraries=&v=weekly&callback=Function.prototype`
      script.async = true
      script.defer = true
      script.onload = () => dispatch({ type: 'SET_GOOGLE_LOADED', payload: true })
      document.body.appendChild(script)
    }

    setTimeout(() => {
      const script = document.createElement('script')
      script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${publicRuntimeConfig.GIGYA_SITE_ID}`
      script.async = true
      script.defer = true
      // eslint-disable-next-line no-eval
      script.onload = () => dispatch({ type: 'ADD_GIGYA_SCRIPT', payload: eval('gigya') })
      document.body.appendChild(script)
    }, 0)

    if (typeof window !== 'undefined' && publicRuntimeConfig.BRANCH_KEY && !state.isBranchLoaded) {
      const BranchSDK = require('branch-sdk')
      BranchSDK.init(publicRuntimeConfig.BRANCH_KEY, {}, (err, data) => {
        if (err) {
          console.warn(err)
        } else {
          dispatch({ type: 'SET_BRANCH_LOADED', payload: true })
        }
      })
    }

    if (typeof window !== 'undefined' && !state.isClaripLoaded) {
      const claripVarScript = document.createElement('script')
      const claripVarScriptCode =
      'if (typeof Storage !== "undefined") { try { var userData = JSON.parse(localStorage.getItem("user")); if (userData && userData.authType) { var claripDNSSCustomerData = {' +
          'loggedIn: true, source: "web", firstName: userData.firstName, lastName: userData.lastName, email: userData.email, phoneNumber: userData.phone }; }} catch (e) { console.warn("error parsing localStorage: ", e);}}' +
          `var globalDnsDeployment = false; var claripHost = "${publicRuntimeConfig.CLARIP_DOMAIN}.clarip.com"; var claripCdnHost = "cdn.clarip.com"; var clientName = "${publicRuntimeConfig.CLARIP_DOMAIN}";`
      claripVarScript.id = 'clarip-dnss'
      claripVarScript.appendChild(document.createTextNode(claripVarScriptCode))
      document.body.appendChild(claripVarScript)

      const claripDoNotSellScript = document.createElement('script')
      claripDoNotSellScript.src = `https://cdn.clarip.com/${publicRuntimeConfig.CLARIP_DOMAIN}/donotsell/assets/js/donotsell-block.min.js`
      document.body.appendChild(claripDoNotSellScript)

      const cookieConsentScript = document.createElement('script')
      cookieConsentScript.src = `https://cdn.clarip.com/${publicRuntimeConfig.CLARIP_DOMAIN}/cookieconsent/assets/js/cookieconsent.min.js`
      document.body.appendChild(cookieConsentScript)

      const claripCustomScript = document.createElement('script')
      const claripCustomScriptCode = `
        var claripCdnHost = "cdn.clarip.com";
        var claripClientName = "${publicRuntimeConfig.CLARIP_DOMAIN}";
        var claripCookieConsentHost = "${publicRuntimeConfig.CLARIP_DOMAIN}.clarip.com";
        var claripCookieManagerIdentifier = "${publicRuntimeConfig.CLARIP_ID}";
      `
      claripCustomScript.id = 'clarip-custom'
      claripCustomScript.appendChild(document.createTextNode(claripCustomScriptCode))
      document.body.appendChild(claripCustomScript)

      const claripCookieManagerScript = document.createElement('script')
      claripCookieManagerScript.src = `https://cdn.clarip.com/${publicRuntimeConfig.CLARIP_DOMAIN}/cookieconsent/assets/js/clarip-cookie-manager.min.js`
      claripCookieManagerScript.onload = () => {
        dispatch({ type: 'SET_CLARIP_LOADED', payload: true })
        if (cpref) {
          setTimeout(() => {
            if (window.claripCookieManager) {
              window.claripCookieManager.setToggle()
              window.claripCookieManager.showClaripCookieManager()
            }
          }, 500)
        }
      }
      document.body.appendChild(claripCookieManagerScript)
    }
  }, [])

  useEffect(() => {
    const { user } = state
    if (user && user.UID && !user.phone && user.authType !== 0) {
      if (router.pathname !== '/registration-phone' && router.pathname !== '/cache-reset') {
        router.push(`/registration-phone?redirectedURL=${router.asPath}`)
      }
    }
  }, [])

  const handleIonScroll = (e: CustomEvent) => {
    if (e.detail.scrollTop > 400) {
      if (!state.showBackToTop) {
        dispatch({ type: 'SHOW_BACK_TO_TOP', payload: true })
      }
    } else {
      if (state.showBackToTop) {
        dispatch({ type: 'SHOW_BACK_TO_TOP', payload: false })
      }
    }
  }

  const noHeaderPaths = ['/login', '/registration', '/forgotpassword', '/newdevice', '/completeregistrationname', '/popshelf']

  return (
    <>
      {typeof window !== 'undefined' && <MainMenu key="main-menu-key" />}
      <IonPage id="mainContentId" className="ion-page">
        <IonHeader slot="fixed">
          <SkipToMain ionRef={ionContent} />
          {!noHeaderPaths.includes(router.pathname) && <Header />}
        </IonHeader>
        <IonContent role="main" scrollEvents={true} ref={ionContent} onIonScroll={handleIonScroll}>
          <div id="body">
            <LoadingProvider>{props.children}</LoadingProvider>
            <IonFooter>
              <Footer/>
            </IonFooter>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
})

Layout.displayName = 'Layout'

const App = ({ Component, pageProps }) => {
  const router = useRouter()
  const layoutRef = useRef<typeof Layout>(null)
  const canonicalURL = ('https://www.popshelf.com' + (router.asPath === '/' ? '' : router.asPath)).split('?')[0]
  const retainedComponents = useRef<any>({})
  const RETAINABLE_ROUTES = ['/search', '/c', '/collection', '/theme']
  const isRetainableRoute = RETAINABLE_ROUTES.some(el => router.asPath.includes(el))
  const externalReferrers = useRef<any>([])
  setupIonicReact({})

  if (isRetainableRoute && (!Object.keys(retainedComponents.current).length || retainedComponents.current.path !== router.asPath)) {
    const MemoComponent = memo(Component)
    retainedComponents.current = {
      component: <MemoComponent {...pageProps} />,
      scrollPos: 0,
      path: router.asPath
    }
  }

  // Save the scroll position of current page before leaving
  const handleRouteChangeStart = async () => {
    if (retainedComponents.current.path === router.asPath) {
      retainedComponents.current.scrollPos = await layoutRef.current.getScrollPosition()
      retainedComponents.current.store = layoutRef.current.getStoreNumber()
    }
  }

  const handleRouteChangeComplete = (url) => {
    if (url.split('/')[1] !== 'p' && retainedComponents.current.path !== url) {
      retainedComponents.current = {}
    }

    if (retainedComponents.current.path !== router.asPath) {
      layoutRef.current.scrollToTop()
    }
  }

  const storeChanged = store => {
    if (retainedComponents.current.store && retainedComponents.current.store !== store.storenumber) {
      retainedComponents.current = {}
    }
  }

  // Save scroll position - requires an up-to-date router.asPath
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.asPath])

  useEffect(() => {
    if (checkCookie()) {
      const storage = globalThis?.localStorage
      if (!storage) {
        return
      }
      let prevPath = ''
      const refPathArray = document.referrer.split('/')
      const refProtocol = refPathArray[0]
      const refHhost = refPathArray[2]
      const referrerUrl = `${refProtocol}//${refHhost}/`

      if (referrerUrl !== `${window.location.protocol}//${window.location.host}/` && !externalReferrers.current.includes(referrerUrl)) {
        externalReferrers.current.push(referrerUrl)
        prevPath = document.referrer
      } else {
        prevPath = storage.getItem('currentPath') ? storage.getItem('currentPath') : window.location.href
      }

      storage.setItem('prevPath', prevPath)
      storage.setItem('currentPath', globalThis.location.href)
    }
  }, [router.asPath])

  // Scroll to the saved position when we load a retained component
  useEffect(() => {
    if (isRetainableRoute && retainedComponents.current.path === router.asPath && retainedComponents.current.scrollPos !== 0) {
      layoutRef.current.scrollToPosition(retainedComponents.current.scrollPos)
    } else {
      layoutRef.current.scrollToTop()
    }
  }, [Component, pageProps])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/sw.js')
          .then((serviceWorker) => {
            console.log('Service Worker registered: ', serviceWorker)
            serviceWorker.update()
          })
          .catch((error) => {
            console.error('Error registering the Service Worker: ', error)
          })

        navigator.serviceWorker.addEventListener('message', event => {
          window.location.reload()
        })
      }
    }
  }, [])

  return (
    <Provider>
      <Head>
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <link rel="canonical" href={canonicalURL} />
        <link rel="icon" type="image/ico" href="/images/favicon.ico?v=2"></link>
        <meta name="msapplication-starturl" content="/index.html" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-touch-fullscreen" content="yes" />
        <meta name="apple-mobile-web-app-title" content="pOpshelf" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="p:domain_verify" content="1958e04b610808dc41d5810a53167d02" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta name="google-site-verification" content="aynxHysMrnknVJxgnah6MC57h5XSk4gTPfOqlaFnQXE" />
        <meta name="facebook-domain-verification" content="fvmzingal0o8xwozs2txo0z62hv5nz" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/icons/180x180.png"
        />
        <link
          href="/images/splash/iphone5_splash.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/iphone6_splash.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/iphoneplus_splash.png"
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/iphonex_splash.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/iphonexr_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/iphonexsmax_splash.png"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/ipad_splash.png"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/ipadpro1_splash.png"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/ipadpro3_splash.png"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/images/splash/ipadpro2_splash.png"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <style dangerouslySetInnerHTML={{ __html: ':where(img, iframe, pre, figure, video, canvas, select) { max-width: 100%; overflow: auto; word-break: break-word; }' }} />
        <link href="//ui.powerreviews.com/tag-builds/10353/4.1/css/styles.css" rel='stylesheet' />
        <meta name="application-name" content="pOpshelf" />
        {/* Clarip */}
        <link rel="stylesheet" type="text/css" href={`https://cdn.clarip.com/${publicRuntimeConfig.CLARIP_DOMAIN}/donotsell/assets/css/donotsell-extended.min.css`} />
        <link rel="stylesheet" type="text/css" href={`https://cdn.clarip.com/${publicRuntimeConfig.CLARIP_DOMAIN}/cookieconsent/assets/css/cookieconsent.min.css`} />
      </Head>
      <IonApp>
        <Layout ref={layoutRef} cb={storeChanged}>
          <div className="main-content">
            <div style={{ display: router.asPath === retainedComponents.current.path ? 'block' : 'none' }}>
              <div
                key={retainedComponents.current.path}
                style={{ display: router.asPath === retainedComponents.current.path ? 'block' : 'none' }}
              >
                {retainedComponents.current.component}
              </div>
            </div>
            {router.asPath !== retainedComponents.current.path && <Component {...pageProps} />}
          </div>
        </Layout>
      </IonApp>
    </Provider>
  )
}

export default withAppInsights(App)
