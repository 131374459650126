import React, { useState, useContext, useEffect } from 'react'
import { useIonModal } from '@ionic/react'
import Context from '../context'
import { useUnauthAPI } from '../hooks/api.hooks'
import { useChangeCartStore, useAddToCart } from '../hooks/cart.hooks'
import LocationErrorModal from './LocationErrorModal'
import SVG from '../components/SVG'
import { StoreDetailsFlyout } from './StoreDetailsFlyout'
import { enterAnimationLeft, leaveAnimationLeft } from '../utils/modal-animations'
import { useRouter } from 'next/router'

const StoreSearch = ({ cartAddData = {}, selectedStoreId = '', onChange = (data) => { }, handleDismiss = () => null, sku = null, isModal = true, findLocation = false, searchText = '' }) => {
  const { dispatch, state } = useContext(Context)
  const [stores, setStores] = useState([])
  const [searchTerm, setSearchTerm] = useState(searchText || '')
  const [loading, setLoading] = useState(false)
  const [fetchStatus, setFetchStatus] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const [openStoreDetails, setOpenStoreDetails] = useState(null)
  const changeCartCall = useChangeCartStore()
  const makeAPICall = useUnauthAPI()
  const makeAddToCartCall = useAddToCart()
  const router = useRouter()
  let isMilesLabel = false

  const history = useRouter()

  useEffect(() => {
    if (state.googleLoaded) {
      if (findLocation) {
        onUseMyLocationClick()
      } else if (searchTerm) {
        onGoClicked()
      } else if (!isModal) {
        fetchStores()
      }
    }
  }, [state.googleLoaded])

  const handleDismissStoreDetails = () => {
    dismissStoreDetails()
  }

  const [presentStoreDetails, dismissStoreDetails] = useIonModal(StoreDetailsFlyout, { dismiss: handleDismissStoreDetails, store: openStoreDetails })

  const handleLocationErrorDismiss = () => {
    dismiss()
  }

  const [present, dismiss] = useIonModal(LocationErrorModal, {
    handleDismiss: handleLocationErrorDismiss
  })

  const fetchStores = (origin?) => {
    makeAPICall('stores', 'store', 'GET', {
      ...((origin?.lat && origin?.lng && !origin?.state) && { lat: origin.lat, lng: origin.lng }),
      ...(origin?.state && { state: origin.state }),
      ...(sku && { sku })
    }, {}).then(async (stores) => {
      if (stores.length) {
        setStores(stores)
        setFetchStatus('data')
      } else {
        setStores([])
        setFetchStatus('empty')
      }
      onChange({ origin, stores })
    })
  }

  useEffect(() => {
    if (findLocation) {
      onUseMyLocationClick()
    } else if (searchTerm) {
      onGoClicked()
    } else if (!isModal) {
      fetchStores()
    }
  }, [])

  const onGoClicked = (location?: any) => {
    setDisableButton(true)
    setTimeout(() => setDisableButton(false), 1000)
    if (location) {
      fetchStores(location)
    } else if (searchTerm) {
      !isModal && router.push({ query: `s=${searchTerm}` }, undefined, { shallow: true })
      if (state.googleLoaded) {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ address: searchTerm, componentRestrictions: { country: 'US' } }, (results, status) => {
          if (status === 'OK' && results.length) {
            if (results[0].types.includes('administrative_area_level_1')) {
              const state = results[0].address_components[0].short_name
              const lat = results[0].geometry.location.lat()
              const lng = results[0].geometry.location.lng()
              fetchStores({ state, lat, lng })
            } else {
              const lat = results[0].geometry.location.lat()
              const lng = results[0].geometry.location.lng()
              fetchStores({ lat, lng })
            }
          } else {
            setFetchStatus('empty')
            setStores([])
          }
        })
      }
    } else {
      if (!isModal && searchTerm === '') {
        router.push({ query: '' }, undefined, { shallow: true })
      }
      fetchStores()
    }
  }

  const handleCloseStoresModal = () => {
    handleDismiss()
  }

  const setStore = async (store) => {
    if (Object.keys(cartAddData).length > 0) {
      // @ts-ignore
      await makeAddToCartCall(store.storenumber, cartAddData.upc, cartAddData.digitalSku, cartAddData.sku, 1, true)
    } else if (Object.keys(cartAddData).length === 0) {
      await changeCartCall(store.storenumber)
    }
    handleCloseStoresModal()
  }

  const error = () => {
    setLoading(false)
    setFetchStatus('location-error')
    present()
  }

  const onUseMyLocationClick = () => {
    setLoading(true)
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }

    const success = (pos) => {
      if (state.googleLoaded) {
        const crd = pos.coords
        const geocoder = new window.google.maps.Geocoder()
        const latlng = {
          lat: crd.latitude,
          lng: crd.longitude
        }

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const locations = results[0].address_components
              const zip = locations.find((x) => x.types.includes('postal_code'))
              !isModal && router.push({ query: `s=${zip.long_name}` }, undefined, { shallow: true })
              dispatch({
                type: 'ADD_LOCATION',
                payload: {
                  lat: crd.latitude,
                  lng: crd.longitude,
                  zip: zip.long_name
                }
              })
              setSearchTerm(zip.long_name)
              onGoClicked({ lat: crd.latitude, lng: crd.longitude })
              setLoading(false)
              setFetchStatus('empty')
            } else {
              dispatch({
                type: 'ADD_LOCATION',
                payload: { lat: crd.latitude, lng: crd.longitude }
              })
              setLoading(false)
              setFetchStatus('data')
            }
          } else {
            dispatch({
              type: 'ADD_LOCATION',
              payload: { lat: crd.latitude, lng: crd.longitude }
            })
            setLoading(false)
            setFetchStatus('empty')
          }
        })
      } else {
        error()
      }
    }
    navigator.geolocation.getCurrentPosition(success, error, options)
  }

  const getLoaderText = () => {
    if (fetchStatus === '') {
      return (
        <p>
          Please enter your<br />zipcode or city and state<br />
          to select a store
        </p>
      )
    } else if (fetchStatus === 'location-error') {
      return (
        <p>This browser does not have location permissions turned on. Please update and try again.</p>
      )
    } else if (fetchStatus === 'empty') {
      return (
        <p>No stores found within 50 miles of your location</p>
      )
    }
  }

  const renderContent = () => {
    return stores.map((store) => {
      const selectButton = (
        <button
          aria-label={'select ' + store.city + ' ' + store.state + ' ' + store.address}
          role="button"
          className={`btn__secondary ${!sku || store?.inventory > 0 ? '' : 'store-search__list-item__content-hidden'}`}
          onClick={() => setStore(store)}
        >
          SELECT
        </button>
      )
      const selectedButton = (
        <div className="store-search__list-item__content__btn-select__selected">
          <SVG name="check-green" label="check-green" size="sm" />
          <p className="store-search__message-text"> SELECTED</p>
        </div>
      )
      const previewText = <label className="store-search__message-text">coming soon!</label>
      const closedText = <label className="store-search__message-text">temporarily closed</label>
      const pegasusText = <label className="store-search__message-text">located inside a DG Market store</label>

      let button = store.storenumber === state.cart.store?.storenumber ? selectedButton : selectButton
      if (store.distance) {
        isMilesLabel = true
      }

      if (store.store_service_names?.includes('DGMarketPopShelf')) {
        button = pegasusText
      } else if (store.store_preview) {
        button = previewText
      } else if (store.is_closed) {
        button = closedText
      }

      return (
        <div className={`store-search__list-item ${selectedStoreId === store.storenumber ? 'store-search__list-item--chosen' : ''}`} key={store.storenumber}>
          <div className="store-search__list-item__content">
            <div className="store-search__list-item__content__label">{`${store.city}, ${store.state}`}</div>
            <div className="store-search__list-item__content__address">{`${store.address}`}</div>
            {isMilesLabel
              ? (
                <div className="store-search__list-item__content__address-distance">
                  {`${store.distance.toFixed(2)} miles away`}
                </div>
                )
              : null}
            {sku && store.inventory > 0 &&
              <div className="store-search__list-item__content-eligible">
                <SVG name="checkmark-green" label="checkmark-green" size="base" />
                <p>in stock</p>
              </div>
            }
            {sku && store.inventory === -1 && (
              <p className="store-search__list-item__content-ineligible">Not Eligible for pickup</p>
            )}
            {sku && (store.inventory === 0 || store.inventory === null) && (
              <p className="store-search__list-item__content-oos">Out of stock</p>
            )}
            {!sku && <button
              className="store-search__list-item__content__btn-details"
              aria-label={`view ${store.city}, ${store.state} details`}
              onClick={() => {
                handleDismiss()
                if (isModal) {
                  history.push(`/store/${store.storenumber}`)
                } else {
                  setOpenStoreDetails(store)
                  presentStoreDetails({ cssClass: 'modal--store__flyout', enterAnimation: enterAnimationLeft, leaveAnimation: leaveAnimationLeft })
                }
              }}
            >
              View store details
            </button>
            }
          </div>
          <div className="store-search__list-item__content__btn-select">{button}</div>
        </div>
      )
    })
  }

  const loader = () => {
    return (
      <div className="store-search__loader">
        <SVG name="alert-full" label="alert-full" size="xl" />
        {getLoaderText()}
      </div>
    )
  }

  const renderUseMyLocation = () => {
    return (
      <button className="store-search__use-my-location" onClick={onUseMyLocationClick}>
        <SVG name="icon-location" label="icon-location" size="base" />
        <div className={`store-search__location__text ${loading && 'store-search__location__text-animated'}`}>Use my location</div>
      </button>
    )
  }

  return (
    <div id="main-content" className="store-search">
      {isModal && <button onClick={() => handleDismiss()} className="store-search__btn-close" ><SVG name="close" label="Close" size="md" /></button>}
      <div className="store-search__container">
        {isModal && <div className="store-search__main-header">
          <h2>stores</h2>
        </div>}
        <form className="store-search__header" onSubmit={(e) => {
          e.preventDefault()
          onGoClicked()
        }}>
          <div className="store-search__header__text">
            <div className="input-wrapper">
              <label htmlFor="enterZipcode">Enter zipcode or city and state</label>
              <input
                id="enterZipcode"
                className="focus"
                type="search"
                aria-label="Enter zipcode"
                placeholder="Enter zipcode or city and state"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="store-search__header__button">
            <button
              type="submit"
              disabled={disableButton}
            >
              GO
            </button>
          </div>
        </form>
        {renderUseMyLocation()}
        {stores.length && fetchStatus !== 'empty' ? renderContent() : loader()}
      </div>
    </div>
  )
}

export default StoreSearch

export const getServerSideProps = async () => {
  return {
    props: {}
  }
}
