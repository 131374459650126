import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { IonMenu, IonContent, useIonModal, IonAccordion, IonItem, IonAccordionGroup, IonLabel } from '@ionic/react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Context from '../context'
import StoreSearch from '../components/StoreSearch'
import { useUnauthAPI } from '../hooks/api.hooks'
import { distance as getDistance } from '../utils/distance'
import { setNavigationLinkClickData, setUserLoginLogoutEventData } from '../utils/analytics'
import SVG from './SVG'

dayjs.extend(customParseFormat)

const MainMenu: React.FC = () => {
  const { state, dispatch } = useContext(Context)
  const [distance, setDistance] = useState<number>()
  const [menuData, setMenuData] = useState([])
  const makeAPICall = useUnauthAPI()
  const router = useRouter()
  const mainMenuRef = useRef(null)
  const mainContentRef = useRef(null)

  useEffect(() => {
    makeAPICall('admin', 'menu', 'GET', {}, {}).then((data) => {
      setMenuData(data)
    })
  }, [])

  useEffect(() => {
    if (Object.keys(state.gigyaScript).length !== 0) {
      window.popEvent = () => console.log('Auth event created')
    }
  }, [])

  const onMenuOpen = () => {
    mainContentRef.current.scrollTo(0, 0)
    mainContentRef.current.focus()
  }

  useEffect(() => {
    if (state.cart.store && state.location?.lat && state.location?.lng) {
      try {
        setDistance(
          getDistance(
            state.location.lat,
            state.location.lng,
            state.cart.store.latitude,
            state.cart.store.longitude,
            'M'
          )
        )
      } catch (e) {
        console.error('error setting distance: ', e)
      }
    }
  }, [state.cart.store, state.location])

  const handleDismiss = () => {
    dismiss()
  }

  const [present, dismiss] = useIonModal(StoreSearch, {
    handleDismiss
  })

  const closeMenu = () => {
    mainMenuRef.current.close()
  }

  const onSignedOut = (response) => {
    if (response.errorCode === 0) {
      router.push('/')
    } else {
      console.warn('Error :' + response.errorMessage)
    }
  }

  const logUserOut = () => {
    closeMenu()
    const emptyUser = {
      UID: '',
      UidSignature: '',
      UidSignatureTimestamp: ''
    }
    dispatch({ type: 'BADGE_FIRST_VISIT', payload: null })
    dispatch({ type: 'USER_CUS_DATA', payload: emptyUser })
    setUserLoginLogoutEventData(false)
    state.gigyaScript.accounts.logout({ callback: onSignedOut })
  }

  const showGigya = (name) => {
    const redirectedURL = router.asPath.substr(1).split('?')[0]
    if (name === 'signin') {
      setNavigationLinkClickData('Sign In')
      router.push({
        pathname: '/login',
        query: { redirectedURL, clickedElement: 'Menu' }
      })
    }
    if (name === 'registration') {
      setNavigationLinkClickData('Register')
      router.push({
        pathname: '/registration',
        query: { redirectedURL, clickedElement: 'Menu' }
      })
    }
    closeMenu()
  }

  return (
    <IonMenu
      data-testid="main-menu"
      ref={mainMenuRef}
      className="main-menu"
      side="start"
      menuId="mainContentId"
      contentId="mainContentId"
      onIonWillOpen={onMenuOpen}
    >
      <IonContent ref={mainContentRef} className="main-menu__content" data-testid="main-menu-content">
        <div className="main-menu__my-account">
          <label aria-label="Welcome to pop shelf">
            Welcome to
            <div className="main-menu__my-account__logo">&nbsp;</div>
          </label>
          <div className="main-menu__close">
            <button id="main-menu__close-button" onClick={closeMenu}>
              <SVG name="close" label="Close" size="md" />
            </button>
          </div>
          {!state.user.authType && (
            <div className="main-menu__my-account__buttons">
              <button
                className="btn__secondary"
                onClick={() => showGigya('signin')}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    showGigya('signin')
                  }
                }}
              >
                SIGN IN
              </button>
              <button
                className="btn__secondary"
                onClick={() => showGigya('registration')}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    showGigya('registration')
                  }
                }}
              >
                REGISTER
              </button>
            </div>
          )}
        </div>
        <div
          data-testid="main-menu-store-button"
          className="main-menu__store"
          tabIndex={0}
          onClick={() => {
            closeMenu()
            present({ cssClass: 'modal--fullscreen' })
            setNavigationLinkClickData('Store Locator')
          }}
        >
          <p className="main-menu__store-text" id="my-store" aria-label="change store from">
            Your store:
          </p>
          <p className="main-menu__store-address">
            {state.cart.store?.address}, {state.cart.store?.city}, {state.cart.store?.state}{' '}
          </p>
          {state.cart?.store?.storenumber && (
            <p className="main-menu__store-opening">
              {distance
                ? `Store is ${distance.toFixed(0)} miles away, closes at ${dayjs(
                    state.cart.store[`hours_${dayjs().format('ddd').toLowerCase()}`].split(':')[1],
                    'hhmm'
                  ).format('h:mm A')}`
                : `Closes at ${dayjs(
                    state.cart.store[`hours_${dayjs().format('ddd').toLowerCase()}`].split(':')[1],
                    'hhmm'
                  ).format('h:mm A')}`}
            </p>
          )}
        </div>
        <div className="main-menu__list">
          <div className="main-menu__list__item">
            <Link href="/">
              <a
                onClick={() => {
                  closeMenu()
                  setNavigationLinkClickData('Home')
                }}
              >
                Home
              </a>
            </Link>
          </div>
          {menuData.length &&
            menuData[0].template.some((e) => e.index === 0) &&
            menuData[0].template
              .filter((e) => e.index === 0)[0]
              .menuComponents.map((item) => {
                return (
                  <div className="main-menu__list__item" key={item.link}>
                    <Link href={item.link}>
                      <a
                        onClick={() => {
                          closeMenu()
                          setNavigationLinkClickData(item.title)
                        }}
                      >
                        {item.title}
                      </a>
                    </Link>
                  </div>
                )
              })}
          <IonAccordionGroup value="main-menu-categories">
            <IonAccordion value="main-menu-categories">
              <IonItem tabIndex={0} className="main-menu__list__item main-menu__accordion-header" slot="header">
                <IonLabel>Shop Categories</IonLabel>
              </IonItem>
              <ul slot="content">
                {menuData.length &&
                  menuData[0].template.some((e) => e.index === 1) &&
                  menuData[0].template
                    .filter((e) => e.index === 1)[0]
                    .menuComponents.map((item) => {
                      return (
                        <li className="main-menu__list__item main-menu__list__item-category" key={item.link}>
                          <Link href={item.link}>
                            <a
                              onClick={() => {
                                closeMenu()
                                setNavigationLinkClickData(item.title)
                              }}
                            >
                              {item.title}
                            </a>
                          </Link>
                        </li>
                      )
                    })}
              </ul>
            </IonAccordion>
          </IonAccordionGroup>
          <div className="main-menu__list__divider" />
          {typeof window !== 'undefined' && !!state.user.authType && (
            <>
              <div className="main-menu__list__item main-menu__list__item-end-menu">
                <Link href="/profile#profile">
                  <a
                    onClick={() => {
                      closeMenu()
                      setNavigationLinkClickData('My Account')
                    }}
                  >
                    My Account
                    {!state.user.zip && <i className="icon icon-alert" />}
                  </a>
                </Link>
              </div>
              <div className="main-menu__list__item main-menu__list__item-end-menu">
                <Link href={{ pathname: '/orders', query: { clickedElement: 'Menu' } }}>
                  <a
                    onClick={() => {
                      closeMenu()
                      const element = document.getElementById('scroll-orders-id') as HTMLElement
                      if (element !== null && element !== undefined) {
                        element.scrollIntoView({ behavior: 'smooth' })
                      }
                      setNavigationLinkClickData('My Orders')
                    }}
                  >
                    My Orders
                  </a>
                </Link>
              </div>
            </>
          )}
          <div
            className="main-menu__list__item main-menu__list__item-end-menu">
            <Link href="/help">
              <a
                onClick={() => {
                  closeMenu()
                  setNavigationLinkClickData('Help')
                }}
              >
                Help
              </a>
            </Link>
          </div>
          <div
            className="main-menu__list__item main-menu__list__item-end-menu">
            <Link href="/careers">
              <a
                onClick={() => {
                  closeMenu()
                  setNavigationLinkClickData('Careers')
                }}
              >
                Careers
              </a>
            </Link>
          </div>
        </div>
        {state.user.authType === 1 && (
          <div className="main-menu__sign-out-container">
            <button
              className="btn__secondary btn__secondary--full"
              onClick={() => {
                logUserOut()
                setNavigationLinkClickData('Sign Out')
              }}
            >
              SIGN OUT
            </button>
          </div>
        )}
      </IonContent>
    </IonMenu>
  )
}

export default MainMenu
